import { Badge, Box, Button, CircularProgress, InputAdornment, Link } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks'
import { Header, HeaderDivider } from '../../styles/header'
import { SectionContent } from '../../styles/section-content'
import { useTranslation } from 'react-i18next'
import {
    selectArchivedProjects,
    selectProjects,
    selectProjectsError,
    selectProjectsSelectedProject,
    selectProjectsSelectedStorageLogin,
    selectProjectsStatus,
} from '../../redux/selectors/projects-selector'
import {
    activateProject,
    createProject,
    fetchArchivedProjects,
    fetchProjects,
    removeProject,
    resetSelected,
    setSelectedProject,
    storageForProject,
} from '../../redux/actions/projectsSlice'
import CustomTable from '../../components/custom-table'
import { projectRoleToString } from '../../utils/utils'
import { selectAccessToken, selectRefreshToken, selectIdToken } from '../../redux/selectors/auth-selector'
import ProjectPanel from '../../components/project-panel'
import StandardButton from '../../styles/button'
import { StandardTableToolbar } from '../../styles/table'
import { Spacer } from '../../styles/spacing'
import StandardTextField from '../../styles/textfield'
import { SearchRounded } from '@mui/icons-material'
import { RpcError } from 'grpc-web'
import CreateProjectDialog from '../../components/create-project-dialog'
import { selectProfile } from '../../redux/selectors/profile-selector'
import TabPanel from '../../components/tab-panels/tab-panel'
import { BigTabs, BigTab } from '../../styles/tabs'
import {store} from "../../redux/store"

const MyProjectsSection = () => {
    const dispatch = useAppDispatch()
    const projects: MyProject[] = useAppSelector(selectProjects)
    const archivedProjects: MyProject[] = useAppSelector(selectArchivedProjects)
    const status: string = useAppSelector(selectProjectsStatus)
    const error: RpcError = useAppSelector(selectProjectsError)
    const project: ProjectInfo = useAppSelector(selectProjectsSelectedProject)
    const profile: Profile = useAppSelector(selectProfile)
    const storageLogin: string = useAppSelector(selectProjectsSelectedStorageLogin)
    const accessToken: string = useAppSelector(selectAccessToken)
    const refreshToken: string = useAppSelector(selectRefreshToken)
    const idToken: string = useAppSelector(selectIdToken)
    const [searchBy, setSearchBy] = useState<string>('')
    const [searchByDelayed, setSearchByDelayed] = useState<string>('')
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const { t } = useTranslation()

    const handleUpdate = useCallback((): void => {
        dispatch(fetchProjects())
        dispatch(fetchArchivedProjects())
    }, [dispatch])

    useEffect(() => {
        const currentStatus = store.getState().projects.status
        if (currentStatus === 'not_loaded') {
            handleUpdate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    const loaded: boolean = useMemo((): boolean => status === 'loaded' || status === 'failed', [status])

    const panelOpened: boolean = useMemo((): boolean => project.project.projectid !== '', [project])

    useEffect((): void => {
        document.title = `${t('projects.projects')} – ${process.env.REACT_APP_TITLE}`
    }, [t])

    useEffect((): void => {
        if (storageLogin && storageLogin.length) {
            window.open(storageLogin, '_blank', 'noreferrer')
        }
    }, [storageLogin])

    const handleActivate = useCallback(
        (project: MyProject) => {
            dispatch(activateProject(project.project.projectid)).then(() => handleUpdate())
        },
        [dispatch, handleUpdate]
    )

    const handleRemove = useCallback(
        (project: MyProject) => {
            dispatch(removeProject(project.project.projectid)).then(() => handleUpdate())
        },
        [dispatch, handleUpdate]
    )

    const handleManage = useCallback(
        (project: MyProject) => {
            dispatch(setSelectedProject({ ...project, usersList: [] }))
        },
        [dispatch]
    )

    const handleStorage = useCallback(
        (project: MyProject) => {
            dispatch(storageForProject(project.project.projectid))
        },
        [dispatch]
    )

    useEffect(() => {
        const timer = setTimeout(() => setSearchBy(searchByDelayed), 500)
        return (): void => clearTimeout(timer)
    }, [searchByDelayed])

    const columns: ColumnData[] = useMemo((): ColumnData[] => {
        const cols = [
            { id: 'name', name: t('projects.name') },
            { id: 'role', name: t('projects.yourRole') },
            { id: 'openAlerts', name: t('projects.openAlerts') },
            { id: 'open', name: '' },
            { id: 'storagelogin', name: '' },
            { id: 'manage', name: '' },
        ]
        return cols
    }, [t])

    const rows: RowData[] = useMemo((): RowData[] => {
        return (selectedTab === 1 ? archivedProjects : projects)
            .map((project: MyProject) => {
                const cells = [project.project.name, t(`projects.${projectRoleToString(project.role)}`), `${project.project.openalertsnoack}/${project.project.openalertsack}`]
                if (selectedTab === 1) {
                    if (project.role === 2) {
                        cells.push(
                            (<Button onClick={() => handleActivate(project)}>{t('projects.activate')}</Button>) as any
                        )
                        cells.push(
                            (
                                <Button color='error' onClick={() => handleRemove(project)}>
                                    {t('projects.remove')}
                                </Button>
                            ) as any
                        )
                    }
                } else {
                    if (project.project.url) {
                        const url = `${project.project.url}?token=${accessToken}&refresh_token=${refreshToken}&id_token=${idToken}`
                        cells.push(
                            (
                                <Button component={Link} href={url} target='_blank' rel='noreferrer'>
                                    {t('projects.open')}
                                </Button>
                            ) as any
                        )
                    } else {
                        cells.push(
                            (
                                <Button component={Link} href='' target='_blank' rel='noreferrer' disabled>
                                    {t('projects.open')}
                                </Button>
                            ) as any
                        )
                    }
                    if (project.project.hasstorage) {
                        cells.push(
                            (
                                <Button onClick={() => handleStorage(project)}>
                                    {t('projects.storagelogin')}
                                </Button>
                            ) as any
                        )
                    } else {
                        cells.push(
                            (
                                <Button component={Link} href='' target='_blank' rel='noreferrer' disabled>
                                    {t('projects.storagelogin')}
                                </Button>
                            ) as any
                        )
                    }
                    if (project.role === 2) {
                        cells.push(
                            (<Button onClick={() => handleManage(project)}>{t('projects.manage')}</Button>) as any
                        )
                    } else {
                        cells.push((<Button disabled>{t('projects.manage')}</Button>) as any)
                    }
                }
                return {
                    id: project.project.projectid,
                    cells,
                }
            })
            .filter((row: RowData): boolean => {
                if (searchBy) {
                    const matches: boolean[] = row.cells
                        .map(
                            (cell: string | number | Element): boolean =>
                                String(cell).toLowerCase().indexOf(searchBy.toLowerCase()) === -1
                        )
                        .filter((result: boolean): boolean => !result)
                    return !!matches.length
                }
                return true
            })
    }, [
        projects,
        archivedProjects,
        selectedTab,
        searchBy,
        accessToken,
        refreshToken,
        idToken,
        handleActivate,
        handleRemove,
        handleManage,
        handleStorage,
        t,
    ])

    const handlePanelClose = useCallback(
        (changed: boolean): void => {
            dispatch(resetSelected())
            if (changed) {
                handleUpdate()
            }
        },
        [dispatch, handleUpdate]
    )

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchByDelayed(event.target.value)
    }

    const handleCreateProjectClick = () => {
        setShowDialog(true)
    }

    const handleCreateProject = async (project: Project) => {
        dispatch(createProject({ project, admin: profile.email })).then(() => {
            dispatch(fetchProjects())
            setShowDialog(false)
        })
    }

    const handleChange = useCallback(
        (_: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue)
        },
        [setSelectedTab]
    )

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const tabBadge = (index: number) => {
        if (loaded) {
            const count = index === 0 ? projects.length : archivedProjects.length
            return <Badge badgeContent={count} color='warning' />
        }
        return <Badge variant='dot' color='warning' />
    }

    return (
        <SectionContent>
            <Header>{t('projects.projects')}</Header>
            <HeaderDivider />
            <Box>
                <BigTabs value={selectedTab} onChange={handleChange} aria-label='users tabs'>
                    <BigTab
                        label={t('projects.activeProjects')}
                        icon={tabBadge(0)}
                        iconPosition='end'
                        {...a11yProps(0)}
                    />
                    <BigTab
                        label={t('projects.archivedProjects')}
                        icon={tabBadge(1)}
                        iconPosition='end'
                        {...a11yProps(1)}
                    />
                </BigTabs>
            </Box>

            {!loaded && <CircularProgress color='inherit' />}
            {loaded && (
                <>
                    <StandardTableToolbar>
                        <StandardTextField
                            id='users-search'
                            placeholder={t('table.search')}
                            type='search'
                            variant='standard'
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchRounded />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Spacer />
                        {selectedTab === 0 && (
                            <StandardButton onClick={handleCreateProjectClick} disabled>
                                {t('projects.createProject')}
                            </StandardButton>
                        )}
                    </StandardTableToolbar>
                    <TabPanel value={selectedTab} index={0}>
                        <CustomTable columns={columns} rows={rows} error={error} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <CustomTable columns={columns} rows={rows} error={error} />
                    </TabPanel>
                </>
            )}
            {panelOpened && <ProjectPanel handleClose={handlePanelClose} handleUpdate={handleUpdate} />}
            {showDialog && (
                <CreateProjectDialog
                    open={showDialog}
                    handleOpen={setShowDialog}
                    handleCreateProject={handleCreateProject}
                />
            )}
        </SectionContent>
    )
}

export default MyProjectsSection
