import { Alert, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import StandardButton from '../styles/button'
import StandardDialog, { DialogContainer } from '../styles/dialog'
import { useTranslation } from 'react-i18next'
import { PlainTextField } from '../styles/textfield'
import { useAppSelector } from '../redux/hooks/hooks'
import { selectCreateProjectError, selectCreateProjectStatus } from '../redux/selectors/projects-selector'

const CreateProjectDialog = ({ open, handleOpen, handleCreateProject }: any) => {
    const status = useAppSelector(selectCreateProjectStatus)
    const error = useAppSelector(selectCreateProjectError)
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const [project, setProject] = useState<Project>({ name: '', description: '', url: '', projectid: '', hasstorage: false, openalertsack: 0, openalertsnoack: 0 })
    const { t } = useTranslation()

    const handleClose = useCallback(() => {
        if (handleOpen) {
            handleOpen(false)
        }
    }, [handleOpen])

    useEffect(() => {
        if (!showAlert && status === 'failed') {
            setShowAlert(true)
        } else if (status === 'success') {
            handleClose()
        }
    }, [status, showAlert, handleClose])

    const alertText = useMemo((): string => {
        if (error) {
            return error.message
        }
        return t('projects.created')
    }, [error, t])

    return (
        <StandardDialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogContainer>
                <DialogTitle id='alert-dialog-title'>{t('projects.createProject')}</DialogTitle>
                <DialogContent>
                    <PlainTextField
                        id='name'
                        type='text'
                        label={t('projects.name')}
                        fullWidth
                        value={project.name || ''}
                        onChange={event => setProject({ ...project, name: event.target.value! })}
                        variant='standard'
                    />

                    <PlainTextField
                        id='url'
                        type='text'
                        label={t('projects.url')}
                        fullWidth
                        value={project.url || ''}
                        onChange={event => setProject({ ...project, url: event.target.value! })}
                        variant='standard'
                    />

                    <PlainTextField
                        id='description'
                        type='text'
                        label={t('projects.description')}
                        fullWidth
                        value={project.description || ''}
                        onChange={event => setProject({ ...project, description: event.target.value! })}
                        variant='standard'
                    />

                    {showAlert && <Alert severity={'error'}>{alertText}</Alert>}
                </DialogContent>
                <DialogActions>
                    <StandardButton variant='outlined' onClick={handleClose}>
                        {t('dialog.cancel')}
                    </StandardButton>

                    <StandardButton
                        onClick={() => handleCreateProject(project)}
                        disabled={!project.name.length || !project.url.length}>
                        {t('dialog.create')}
                    </StandardButton>
                </DialogActions>
            </DialogContainer>
        </StandardDialog>
    )
}

export default CreateProjectDialog
