import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { callPing } from '../../grpc/api'

const initialState: VersionState = {
    apiVersion: 1,
    status: 'not_loaded',
    error: null,
}

export const versionSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        setApiVersion: (state, action: PayloadAction<number>) => {
            state.apiVersion = action.payload
            state.status = 'loaded'
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchVersion.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchVersion.fulfilled, (state, action) => {
                state.status = 'loaded'
                state.apiVersion = action.payload
                state.error = null
            })
            .addCase(fetchVersion.rejected, (state, action) => {
                state.status = 'failed'
                state.error = { ...action.error, code: Number.parseInt(action.error.code!) }
            })
    },
})

export const fetchVersion = createAsyncThunk('profile/fetchVersion', async (): Promise<number> => {
    return (await callPing()).api
})

export const { setApiVersion } = versionSlice.actions

export default versionSlice.reducer
