import { useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Dialog from '../components/dialog'
import SideBar from '../components/sidebar'
import { checkToken } from '../grpc/api'
import { fetchProfile } from '../redux/actions/profileSlice'
import { fetchVersion } from '../redux/actions/versionSlice'
import { useAppDispatch, useAppSelector } from '../redux/hooks/hooks'
import {
    selectAccessToken,
    selectAuthStatus,
    selectIdToken,
    selectRefreshToken,
    selectUuid,
} from '../redux/selectors/auth-selector'
import { selectProfileError, selectProfileStatus } from '../redux/selectors/profile-selector'
import { selectApiVersionStatus } from '../redux/selectors/version-selector'
import { MainContainer } from '../styles/page'
import { ServiceUnavailableDialog, UnableToAuthenticateDialog } from '../utils/dialogs'
import { FlexRow } from '../styles/flex'
import { selectProjectsError, selectProjectsStatus } from '../redux/selectors/projects-selector'
import { fetchProjects } from '../redux/actions/projectsSlice'
import { RpcError } from 'grpc-web'
import { selectProductsError } from '../redux/selectors/downloads-selector'
import { selectSeatAssignmentsError } from '../redux/selectors/subscriptions-selector'
import {store} from "../redux/store";

const Root: React.FC = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const status: string = useAppSelector(selectAuthStatus)
    const accessToken: string = useAppSelector(selectAccessToken)
    const refreshToken: string = useAppSelector(selectRefreshToken)
    const idToken: string = useAppSelector(selectIdToken)
    const uuid: string = useAppSelector(selectUuid)
    const apiVersionStatus: LoadStatus = useAppSelector(selectApiVersionStatus)
    const profileStatus: LoadStatus = useAppSelector(selectProfileStatus)
    const projectsStatus: LoadStatus = useAppSelector(selectProjectsStatus)

    const profileError: RpcError = useAppSelector(selectProfileError)
    const projectsError: RpcError = useAppSelector(selectProjectsError)
    const productsError: RpcError = useAppSelector(selectProductsError)
    const seatAssignmentsError: RpcError = useAppSelector(selectSeatAssignmentsError)

    const [showDialog, setShowDialog] = useState<boolean>(false)

    useEffect((): void => {
        if (accessToken || refreshToken) {
            localStorage.setItem('uuid', JSON.stringify(uuid))
            localStorage.setItem('access_token', JSON.stringify(accessToken))
            localStorage.setItem('refresh_token', JSON.stringify(refreshToken))
            localStorage.setItem('id_token', JSON.stringify(idToken))
        }
    }, [uuid, accessToken, refreshToken, idToken])

    useEffect((): void => {
        checkToken()
        const currentProfileStatus = store.getState().profile.status
        if (location.pathname !== '/profile/about' && currentProfileStatus === 'not_loaded') {
            dispatch(fetchProfile())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileStatus])

    useEffect((): void => {
        checkToken()
        const currentProjectsStatus = store.getState().projects.status
        if (location.pathname !== '/projects/my-projects' && currentProjectsStatus === 'not_loaded') {
            dispatch(fetchProjects())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsStatus])

    useEffect((): void => {
        checkToken()
        const currentVersionStatus = store.getState().version.status
        if (currentVersionStatus === 'not_loaded') {
            dispatch(fetchVersion())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiVersionStatus])

    const serviceUnavailable: boolean = useMemo(
        (): boolean =>
            profileError?.code === 14 ||
            projectsError?.code === 14 ||
            productsError?.code === 14 ||
            seatAssignmentsError?.code === 14,
        [profileError, projectsError, productsError, seatAssignmentsError]
    )

    useEffect((): void => {
        setShowDialog(serviceUnavailable)
    }, [serviceUnavailable])

    const dialogProps: ModalDialogProps = useMemo((): ModalDialogProps => {
        return {
            ...UnableToAuthenticateDialog,
            open: status === 'failed',
        }
    }, [status])

    const errorDialogProps: ModalDialogProps = useMemo((): ModalDialogProps => {
        return {
            ...ServiceUnavailableDialog,
            open: showDialog,
            handleOpen: setShowDialog,
        }
    }, [showDialog])

    return (
        <FlexRow>
            <SideBar />
            <MainContainer>
                <Outlet />
            </MainContainer>
            {serviceUnavailable ? <Dialog {...errorDialogProps} /> : <Dialog {...dialogProps} />}
        </FlexRow>
    )
}

export default Root
